import  {useEffect }from "react";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from "./Components/Header";
import Home from "./Pages/Home";

import './App.css';



function App() {

  useEffect(() => {
    if (!hotjar.initialized() && process.env.REACT_APP_HOTJAR_ID) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR_ID, +(process.env.REACT_APP_HOTJAR_VERSION || 6))
    }

    if (process.env.REACT_APP_GA_TRACKING_ID && !ReactGA.isInitialized) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
    }
  }, [])


  return (
    <Router>
          <Header />        
            <Routes>
              <Route path="/" element={<Home />} />       
            </Routes>
          {/* <Footer />          */}
    </Router>
  );
}

export default App;
